import { createPersistedQueryLink } from "apollo-link-persisted-queries"
import { ApolloLink } from "apollo-link"
import { HttpLink } from "apollo-link-http"
import { setContext } from "apollo-link-context"
import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { onError } from "apollo-link-error"
import Cookies from "js-cookie"

// Websockets are used to handle subscriptions but currently we don't have
// any subscriptions so disable ws layer
//
// import { WebSocketLink } from "apollo-link-ws"
// import ws from "ws"
// import { getOperationAST } from "graphql"
// import { SubscriptionClient } from "subscriptions-transport-ws"

// let wsClient: SubscriptionClient | null = null

const isDev = process.env.NODE_ENV !== "production"

// export function resetWebsocketConnection(): void {
//   if (wsClient) {
//     wsClient.close(false, false)
//   }
// }

function makeMainLink(ROOT_URL: string) {
  const httpLink = new HttpLink({
    uri: `${ROOT_URL}/graphql`,
    credentials: isDev ? "include" : "same-origin",
  })

  // wsClient = new SubscriptionClient(
  //   `${ROOT_URL.replace(/^http/, "ws")}/graphql`,
  //   {
  //     reconnect: true,
  //   },
  //   typeof WebSocket !== "undefined" ? WebSocket : ws
  // )
  // const wsLink = new WebSocketLink(wsClient)

  // Using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent.
  // const mainLink = split(
  //   // split based on operation type
  //   ({ query, operationName }) => {
  //     const op = getOperationAST(query, operationName)
  //     return (op && op.operation === "subscription") || false
  //   },
  //   wsLink,
  //   httpLink
  // )

  return httpLink
}

function createClient() {
  const ROOT_URL = process.env.REACT_APP_ROOT_URL
  if (!ROOT_URL) {
    throw new Error("ROOT_URL envvar is not set")
  }

  const cache = new InMemoryCache()

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions?.code === "UNAUTHENTICATED") {
          const authStatus = localStorage.getItem("client-authorized")
          if (authStatus !== "false")
            localStorage.setItem("client-authorized", "false")

          return
        }

        console.error(
          `[GraphQL error]: message: ${message}, location: ${JSON.stringify(
            locations
          )}, path: ${JSON.stringify(path)}`
        )
      })
    if (networkError) console.error(`[Network error]: ${networkError}`)
  })

  const csrfLink = setContext((_, { headers }) => {
    const token = Cookies.get("xsrf-token")
    return {
      headers: {
        ...headers,
        "x-csrf-token": token ? token : "",
      },
    }
  })

  const persistedQueryLink = createPersistedQueryLink()
  const mainLink = makeMainLink(ROOT_URL)

  const link = ApolloLink.from([
    csrfLink,
    onErrorLink,
    persistedQueryLink,
    mainLink,
  ])

  const client = new ApolloClient({
    link,
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
  })

  return client
}

const client = createClient()

export default client
