import React from "react"

import { Modal } from "antd"

import WorkspaceNameSet from "./name"

interface cwModalPorps {
  onSend: (name: string) => void
  onVisibleChange: (value: boolean) => void
  visible: boolean
  loading?: boolean
}

const CreateWorkspaceModal: React.FC<cwModalPorps> = (props: cwModalPorps) => {
  const { onSend, onVisibleChange, visible, loading } = props

  return (
    <Modal
      visible={visible}
      width="450px"
      centered={true}
      closable={true}
      onCancel={onVisibleChange.bind(null, false)}
      footer={null}
    >
      <div>
        <p style={{ fontSize: "30px", color: "black" }}>Set a name for your new <br/> organization</p>
        <WorkspaceNameSet
          horizontal
          submitText="Create"
          isSending={loading}
          onSend={onSend}
          currentName="New organization"
          allowUnchanged
        />
      </div>
    </Modal>
  )
}

export default CreateWorkspaceModal
