import React, { useEffect } from "react"
import { useNavigation } from "react-navi"
import { mount, route, redirect, map } from "navi"

const unauthorizedRoutes = [
  "/login",
  "/login-totp",
  "/login-recovery-key",
  "/login-okta",
  "/login-okta/denied",
  "/login-okta/callback",
  "/register",
  "/forgot",
  "/invite",
]

export const checkAuthRequired = (pathname: string): boolean => {
  return !unauthorizedRoutes.includes(pathname)
}

const routes = mount({
  "/": route({
    getView: () => import("../pages/Dashboard"),
  }),
  "/onboarding": route({
    getView: () => import("../pages/Onboarding"),
  }),
  "/credentials": route({
    getView: () => import("../pages/Credentials"),
  }),
  "/detections": route({
    getView: async (req, _context) => {
      const { Alerts } = await import("../pages/Detections")
      return <Alerts />
    },
  }),
  "/incidents": route({
    getView: async (req, _context) => {
      const { Incidents } = await import("../pages/Incidents")
      return <Incidents />
    },
  }),
  "/incidents/:id": route({
    getView: async (req, _context) => {
      const { IncidentDetailsPage } = await import("../pages/IncidentDetails")
      return <IncidentDetailsPage id={req.params.id} />
    },
  }),
  "/escalation-policies": route({
    getView: () => import("../pages/Escalations"),
  }),
  "/escalation-policies/new": route({
    getView: () => import("../pages/CreateEscalation"),
  }),
  "/escalation-policies/:id": route({
    getView: async (req, _context) => {
      const { EscalationDetailsPage } = await import(
        "../pages/EscalationDetails"
      )
      return <EscalationDetailsPage id={req.params.id} />
    },
  }),
  "/destinations": route({
    getView: async (req, _context) => {
      const { OutputsPage } = await import("../pages/Outputs")
      return <OutputsPage />
    },
  }),
  "/destinations/new": route({
    getView: async (req, _context) => {
      const { OutputsPage } = await import("../pages/Outputs")
      return <OutputsPage isNew />
    },
  }),
  "/destinations/email/:id": route({
    getView: async (req, _context) => {
      const { OutputsPage } = await import("../pages/Outputs")
      return <OutputsPage id={req.params.id} type="email" />
    },
  }),
  "/destinations/slack/:id": route({
    getView: async (req, _context) => {
      const { OutputsPage } = await import("../pages/Outputs")
      return <OutputsPage id={req.params.id} type="slack" />
    },
  }),
  "/destinations/cribl/:id": route({
    getView: async (req, _context) => {
      const { OutputsPage } = await import("../pages/Outputs")
      return <OutputsPage id={req.params.id} type="cribl" />
    },
  }),
  "/no-organization": map(async (_request, _context) =>
    route({
      getView: () => import("../pages/NoOrganization"),
    })
  ),
  "/organization": redirect("/organization/general"),
  "/organization/general": route({
    getView: async (req, _context) => {
      const { Organization } = await import("../pages/Organization")
      return <Organization page="/organization/general" />
    },
  }),
  "/organization/licensing": route({
    getView: async (req, _context) => {
      const { Organization } = await import("../pages/Organization")
      return <Organization page="/organization/licensing" />
    },
  }),
  "/organization/teammates": route({
    getView: async (req, _context) => {
      const { Organization } = await import("../pages/Organization")
      return <Organization page="/organization/teammates" />
    },
  }),
  "/organization/okta": route({
    getView: async (req, _context) => {
      const { Organization } = await import("../pages/Organization")
      return <Organization page="/organization/okta" />
    },
  }),
  "/sources": route({
    getView: async (req, _context) => {
      const { DataSources } = await import("../pages/DataSources")
      return <DataSources page="/sources" />
    },
  }),
  "/sources/azure": route({
    getView: async (req, _context) => {
      const { DataSources } = await import("../pages/DataSources")
      return <DataSources page="/sources/azure" />
    },
  }),
  "/groups": route({
    getView: () => import("../pages/Groups"),
  }),
  "/profile": route({
    getView: () => import("../pages/AccountSettings"),
  }),
  "/login": map(async (_request, _context) =>
    route({
      getView: async (req, _context) => {
        const { AuthPage } = await import("../pages/Auth")
        return <AuthPage next={req.params.next} login />
      },
    })
  ),
  "/login-totp": map(async (_request, _context) =>
    route({
      getView: async (req, _context) => {
        const { AuthPage } = await import("../pages/Auth")
        return <AuthPage next={req.params.next} totp />
      },
    })
  ),
  "/login-recovery-key": map(async (_request, _context) =>
    route({
      getView: async (req, _context) => {
        const { AuthPage } = await import("../pages/Auth")
        return <AuthPage next={req.params.next} recovery />
      },
    })
  ),
  "/register": map(async (_request, _context) =>
    route({
      getView: async (req, _context) => {
        const { AuthPage } = await import("../pages/Auth")
        return <AuthPage next={req.params.next} register />
      },
    })
  ),
  "/forgot": map(async (_request, _context) =>
    route({
      getView: async (req, _context) => {
        const { AuthPage } = await import("../pages/Auth")
        return <AuthPage next={req.params.next} forgot />
      },
    })
  ),
  "/invite": map(async (_request, _context) =>
    route({
      getView: async (req, _context) => {
        const { AuthPage } = await import("../pages/Auth")
        return <AuthPage next={req.params.next} invite />
      },
    })
  ),
  "/login-okta": route({
    getView: async (req, _context) => {
      const { AuthOktaPage } = await import("../pages/AuthOkta")
      return <AuthOktaPage next={req.params.next} />
    },
  }),
  "/login-okta/callback": route({
    getView: () => import("../pages/LoginOktaCallback")
  }),
  "/login-okta/denied": route({
    getView: () => import("../pages/OktaDenied")
  })
})

export interface RedirectProps {
  href: string
  timeout?: number
  noText?: boolean
  replace?: boolean
}

export function Redirect({ href, timeout, noText, replace }: RedirectProps) {
  const navigation = useNavigation()
  useEffect(() => {
    setTimeout(() => {
      if (navigation._history.location.pathname === href) return

      if (replace) navigation._history.replace(href)
      else navigation.navigate(href)
    }, timeout || 0)
  }, [href, replace, timeout, navigation])
  return <div>{noText ? "" : "Redirecting..."}</div>
}

export default routes
