import { ApolloError } from "apollo-client"

export const errorWrapper = (e: ApolloError | undefined): string => {
  if (!e) return ""

  const { graphQLErrors } = e
  let errorMessage: string = ""

  if (!graphQLErrors) return ""

  graphQLErrors.forEach(gqlerr => {
    if (gqlerr.extensions?.code === "UNAUTHENTICATED") return

    errorMessage += errorMessage === "" ? gqlerr.message : `, ${gqlerr.message}`
  })

  return errorMessage
}

export const parseAeLicenseError = (e: ApolloError | undefined): string => {
  if (!e) return ""

  const { graphQLErrors } = e
  if (!graphQLErrors) return ""

  var msg = ""
  graphQLErrors.forEach(gqlerr => {
    if (gqlerr.extensions?.code === "LICENSE_AE_NOT_ACTIVE") {
      msg = gqlerr.message
      return
    }
  })

  return msg
}
