import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react"

interface RefetchProviderProps {
  children: React.ReactNode
}

export interface RefetchRef {
  forceRefetch: () => void
}

export const RefetchProvider = forwardRef<RefetchRef, RefetchProviderProps>(
  ({ children }, ref) => {
    const [state, setState] = useState(0)

    const forceRefetch = useCallback(() => {
      setState(1)
      setState(0)
    }, [])

    useImperativeHandle(ref, () => ({ forceRefetch }))

    return <>{state === 0 ? children : null}</>
  }
)
