import React from "react"

import { useNavigation } from "react-navi"

import { Result, Button } from "antd"

const Page404: React.FC = () => {
  const navigation = useNavigation()

  const onClick = () => {
    navigation.navigate("/")
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button shape="round" onClick={onClick} type="primary">
          Back to Dashboard
        </Button>
      }
    />
  )
}

export default Page404
