import React, { useState } from "react"

import { Form, Input, Button, Spin, Popover, Icon, Tooltip } from "antd"

import { FormComponentProps } from "antd/lib/form"

import "./style.css"

interface WorkspaceNameChangeProps extends FormComponentProps {
  onSend: (name: string) => void
  allowUnchanged?: boolean
  readOnly?: boolean
  currentName: string
  isSending?: boolean
  error?: string
  success?: string
  submitText?: string
  horizontal?: boolean
}

const WorkspaceNameChange: React.FC<WorkspaceNameChangeProps> = (
  props: WorkspaceNameChangeProps
) => {
  const {
    onSend,
    currentName,
    isSending,
    form,
    readOnly,
    horizontal,
    submitText,
    allowUnchanged,
  } = props

  const [invalid, setInvalid] = useState<boolean>(!allowUnchanged)

  const onSubmit = (e: any) => {
    e.preventDefault()
    form.validateFields(onValidation)
  }

  const onValidation = (err: Error) => {
    if (!err) {
      onSend(form.getFieldValue("name"))
    }
  }

  const validatorWorkspaceName = (_: any, value: any, callback: any) => {
    if (value.length < 2 || value.length > 120) {
      setInvalid(true)
      callback("Organization name must be between 2 and 120 characters")
    } else if (!allowUnchanged && value === currentName) {
      setInvalid(true)
    } else {
      setInvalid(false)
      callback()
    }
  }

  const workspaceNameLabel = () => {
    const popoverContent = (
      <span>
        <strong>Organization name requirements:</strong>
        <ul>
          <li>- between 2 and 120 characters long</li>
        </ul>
      </span>
    )

    return (
      <span>
        ORGANIZATION NAME
        <Popover
          overlayClassName="workspace-form-help"
          content={popoverContent}
        >
          <Icon type="question-circle" style={{ marginLeft: "5px" }} />
        </Popover>
      </span>
    )
  }

  const workspaceNameInput = () => {
    return form.getFieldDecorator("name", {
      rules: [
        {
          validator: validatorWorkspaceName,
        },
      ],
      initialValue: currentName,
    })(<Input size="large" placeholder="Name" readOnly={readOnly} />)
  }

  return (
    <Form
      layout="inline"
      onSubmit={onSubmit}
      hideRequiredMark={true}
      className="workspace-form"
    >
      <Spin spinning={!!isSending}>
        {workspaceNameLabel()}
        <div style={{display:"flex", flexDirection: horizontal ? "column" : "row" }}>
          <Form.Item colon={false}>
            {workspaceNameInput()}
          </Form.Item>
          <Form.Item>
            <Tooltip
              title={
                readOnly
                  ? "Only organization owner can change this"
                  : "You cannot save unchanged organization name"
              }
              overlayStyle={{ display: invalid ? "" : "none" }}
            >
              <Button
                shape="round"
                htmlType="submit"
                type="primary"
                disabled={isSending || invalid || readOnly}
              >
                {submitText || "Save"}
              </Button>
            </Tooltip>
          </Form.Item>
        </div>
      </Spin>
    </Form>
  )
}

export default Form.create<WorkspaceNameChangeProps>()(WorkspaceNameChange)
