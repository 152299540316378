import React from "react"

import "./profileIcon.css"

interface ProfileIconProps {
  workspaceName: string
  size: number
}

const hashString = (str: string): number => {
  if (str.length === 0) return 0

  let hash: number = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
    hash &= hash
  }

  return hash
}

const ProfileIcon: React.FC<ProfileIconProps> = ({
  workspaceName,
  size,
}: ProfileIconProps) => {
  const hue: number = hashString(workspaceName) % 360

  const letter: string = workspaceName.charAt(0).toUpperCase()
  const style: React.CSSProperties = {
    backgroundColor: `hsl(${hue}, 70%, 30%)`,
    color: "white",
    width: `${size}px`,
    height: `${size}px`,
    minWidth:`${size}px`,
    minHeight: `${size}px`,
    fontSize: `${size / 2}px`,
  }

  return (
    <div className="ui-profile-icon" style={style}>
      {letter}
    </div>
  )
}

export default ProfileIcon
