import React, { useState, useEffect } from "react"
import { Link, useCurrentRoute, useNavigation } from "react-navi"

import { Layout, Menu, Icon, Popover, message, Spin, Badge, Tooltip } from "antd"
import { errorWrapper } from "../../helpers/error"

import {
  Workspace,
  useWorkspacesQuery,
  useChangeWorkspaceMutation,
  useNewWorkspaceMutation,
  useOnboardingSetVisitedMutation,
  Role,
  WorkspacesDocument,
} from "@app/graphql"

import CreateWorkspaceModal from "../organization/create"
import ProfileIcon from "../ui/profileIcon"
import { useSession } from "../../router/SessionContext"
import { ApolloError } from "apollo-client"

import AsocLogo from "../../media/img/asoc-logo.svg"
import OnboardingIcon from "../../media/img/onboarding-icon.svg"
import DashboardIcon from "../../media/img/dashboard-icon.svg"
import DetectionsIcon from "../../media/img/detections-icon.svg"
import IncidentsIcon from "../../media/img/incidents-icon.svg"
import EscalationsIcon from "../../media/img/escalations-icon.svg"
import GroupsIcon from "../../media/img/groups-icon.svg"
import SourcesIcon from "../../media/img/sources-icon.svg"
import DestinationsIcon from "../../media/img/destinations-icon.svg"
import LogoutIcon from "../../media/img/logout-icon.svg"
import SettingsIcon from "../../media/img/settings-icon.svg"
import UserIcon from "../../media/img/user-icon.svg"
import ArrowIcon from "../../media/img/arrow-icon.svg"

import "./style.css"

interface SidebarProps {
  refetchPage?: () => void
}

const Sidebar: React.FC<SidebarProps> = ({ refetchPage }: SidebarProps) => {
  const session = useSession()

  const onboardingActive: boolean =
    !!session.onboarding &&
    !session.onboarding?.skipped &&
    !session.onboarding?.completed
  const demoOnboardingActive: boolean =
    onboardingActive && !session.onboarding?.demo
  const sourcesOnboardingActive: boolean =
    onboardingActive && !session.onboarding?.sources
  const alertsOnboardingActive: boolean =
    onboardingActive && !session.onboarding?.alerts
  const escalationsOnboardingActive: boolean =
    onboardingActive && !session.onboarding?.escalations
  const groupsOnboardingActive: boolean =
    onboardingActive && !session.onboarding?.groups

  const route = useCurrentRoute()
  const navigation = useNavigation()

  let selectedKey: string = route.url.pathname
  if (selectedKey.startsWith("/destinations")) selectedKey = "/destinations"
  if (selectedKey.startsWith("/sources")) selectedKey = "/sources"
  if (selectedKey.startsWith("/escalation-policies"))
    selectedKey = "/escalation-policies"
  if (selectedKey.startsWith("/organization/ssh-keys"))
    selectedKey = "/organization/api-keys"

  const [createOpen, setCreateOpen] = useState(false)
  const [userOpen, setUserOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const [
    onboardingSetVisited,
    onboardingSetVisitedStatus,
  ] = useOnboardingSetVisitedMutation()

  const workspacesQuery = useWorkspacesQuery({})

  const [newWorkspace, newWorkspaceStatus] = useNewWorkspaceMutation({
    onCompleted: () => {
      navigation.navigate("/")
      session.handleWorkspaceChange(refetchPage)
    },
  })
  const [changeWorkspace, workspaceStatus] = useChangeWorkspaceMutation({
    onCompleted: () => session.handleWorkspaceChange(refetchPage),
  })

  useEffect(() => {
    /* eslint-disable */
    if (
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1
    ) {
      document.body.classList.add("safari")
    }
    /* eslint-enable */

    if (route.url.query["demo-popup"] === "true") {
      navigation._history.replace("/onboarding")
      setUserOpen(true)
    }

    if (onboardingSetVisitedStatus.loading || session.onboardingLoading) return

    if (selectedKey === "/detections") {
      onAcceptOnboardingAlerts()
    } else if (selectedKey === "/groups") {
      onAcceptOnboardingGroups()
    } else if (selectedKey === "/sources") {
      onAcceptOnboardingSources()
    } else if (selectedKey === "/escalation-policies") {
      onAcceptOnboardingEscalations()
    }

    setMenuOpen(false)
    /* eslint-disable */
  }, [selectedKey, route.url.query, navigation._history])
  /* eslint-enable */

  const onVisibleChange = (val: boolean) => {
    setUserOpen(val)
  }

  const onAcceptOnboardingGroups = async () => {
    if (!groupsOnboardingActive || session.workspace?.demo) return

    try {
      await onboardingSetVisited({ variables: { groups: true } })
      message.success("[Onboarding] Review your settings: done!")
      session.refetchOnboarding()
    } catch (e) {}
  }

  const onAcceptOnboardingEscalations = async () => {
    if (!escalationsOnboardingActive || session.workspace?.demo) return

    try {
      await onboardingSetVisited({ variables: { escalations: true } })
      message.success("[Onboarding] Configure escalations: done!")
      session.refetchOnboarding()
    } catch (e) {}
  }

  const onAcceptOnboardingAlerts = async () => {
    if (!alertsOnboardingActive || session.workspace?.demo) return

    try {
      await onboardingSetVisited({ variables: { alerts: true } })
      session.refetchOnboarding()
    } catch (e) {}
  }

  const onAcceptOnboardingSources = async () => {
    if (!sourcesOnboardingActive || session.workspace?.demo) return

    try {
      await onboardingSetVisited({ variables: { sources: true } })
      session.refetchOnboarding()
    } catch (e) {}
  }

  const onCreateWorkspace = async (name: string) => {
    try {
      await newWorkspace({
        variables: {
          name,
        },
        update(cache, mutation) {
          cache.writeQuery({
            query: WorkspacesDocument,
            data: {
              ...workspacesQuery.data,
              workspaces: {
                ...workspacesQuery.data?.workspaces,
                nodes: [
                  ...(workspacesQuery.data?.workspaces?.nodes.map(workspace => ({
                    ...workspace,
                    current: false
                  })) || []),
                  {
                    name,
                    demo: false,
                    role: "OWNER",
                    current: true,
                    id: mutation.data?.newWorkspace?.uuid,
                    __typename: "Workspace"
                  }
                ]
              }
            }
          })
        }
      })

      setCreateOpen(false)
      message.success("Organization has been created.")
    } catch (e) {
      message.destroy()
      const filteredError = errorWrapper(e as ApolloError)
      if (filteredError) message.error(filteredError)
    }
  }

  const onCreateWorkspaceClick = () => {
    onVisibleChange(false)
    setCreateOpen(true)
  }

  const onWorkspaceSwitch = async (id: string, demo: boolean) => {
    if (id === session.workspace?.id) return

    try {
      await changeWorkspace({
        variables: {
          id,
        },
      })

      setUserOpen(false)

      if (demo && demoOnboardingActive) {
        await onboardingSetVisited({ variables: { demo: true } })
        message.success("[Onboarding] Explore the demo organization: done!")
      } else {
        message.success("Organization has been switched.")
      }
      session.refetchOnboarding()

      navigation.navigate("/")
    } catch (e) {
      message.destroy()
      const filteredError = errorWrapper(e as ApolloError)
      if (filteredError) message.error(filteredError)
    }
  }

  useEffect(() => {
    const handler = () => {
      setIsMobile(window?.innerWidth < 1000)
    }
    window?.addEventListener("resize", handler)
    return () => {
      window?.removeEventListener("resize", handler)
    }
  }, [])

  const switchContent: React.ReactNode = (
    <Spin spinning={!!workspaceStatus.loading}>
      <ul className="popover-workspace-switch">
        {workspacesQuery.data?.workspaces?.nodes
          .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
          .map((w: Workspace) => (
            <li
              className={
                session.workspace?.id === w.id
                  ? "workspace-switch-disabled"
                  : ""
              }
              key={w.id}
              onClick={onWorkspaceSwitch.bind(null, w.id || "", !!w.demo)}
            >
              {w.name || ""}
              {demoOnboardingActive && w.demo ? (
                <Badge status={"processing"} color="red" />
              ) : null}
              {session.workspace?.id === w.id ? (
                <Icon className="workspace-switch-icon" type="check" />
              ) : null}
            </li>
          ))}
        <li onClick={onCreateWorkspaceClick}>
          <Icon type="plus" />
          New organization
        </li>
      </ul>
    </Spin>
  )

  const highlightUserMenu: boolean = demoOnboardingActive && !userOpen

  return (
    <>
    <div className="sidebar-top-menu">
      <Link href="/" className="logo">
        <img
          src={AsocLogo}
          title="AlphaSOC"
          alt="AlphaSOC"
        />
      </Link>
      <div className={`top-menu-user ${menuOpen ? "top-menu-user-active" : ""}`}>
        {highlightUserMenu ? (
          <Badge count={1} status={"processing"} color="red" />
        ) : null}
        <CreateWorkspaceModal
          onSend={onCreateWorkspace}
          onVisibleChange={setCreateOpen}
          visible={createOpen}
          loading={newWorkspaceStatus.loading}
        />
        <Popover
          visible={userOpen}
          onVisibleChange={onVisibleChange}
          content={switchContent}
          overlayClassName="popover-user-nav"
          trigger="click"
          placement={isMobile ? undefined : "bottom"}
        >
          <div className="top-menu-user-option">
            <ProfileIcon
              size={42}
              workspaceName={session.workspace?.name || ""}
            />
            <div className="top-menu-user-details">
              <b>{session.workspace?.name}</b>
              {session.user?.fullName || session.user?.email}
            </div>
            <img src={ArrowIcon} style={{ transform: userOpen ? "rotate(180deg)" : undefined }} />
          </div>
        </Popover>
        <Tooltip placement="bottom" title="My account">
          <Link href="/profile" className="top-menu-link">
            <img src={UserIcon} />
          </Link>
        </Tooltip>
        <Tooltip placement="bottom" title="Organization settings">
          <Link href="/organization" className="top-menu-link">
            <img src={SettingsIcon} />
          </Link>
        </Tooltip>
        <Tooltip placement="bottom" title="Log out">
          <Link href="/" onClick={session.handleLogout} className="top-menu-link">
            <img src={LogoutIcon} />
          </Link>
        </Tooltip>
      </div>
      <div
        onClick={() => setMenuOpen(!menuOpen)}
        className={`menu-burger ${menuOpen ? "menu-burger-active" : ""}`}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div style={{paddingTop: "64px"}}></div>
    <Layout.Sider width={250} className={`sidebar-main ${menuOpen ? "sidebar-main-active" : ""}`}>
      <Menu selectedKeys={[selectedKey]} mode="inline" theme="dark">
        {onboardingActive ? (
          <Menu.Item key="/onboarding">
            <Badge className="menu-badge" status={"processing"} color="red" />
            <img src={OnboardingIcon} />
            <span>Onboarding</span>
            <Link href="/onboarding" />
          </Menu.Item>
        ) : null}
        <Menu.Item key="/">
            <img src={DashboardIcon} />
          <span>Dashboard</span>
          <Link href="/" />
        </Menu.Item>
        <Menu.Item key="/detections">
          {alertsOnboardingActive ? (
            <Badge className="menu-badge" status={"processing"} color="red" />
          ) : null}
          <img src={DetectionsIcon} />
          <span>Detections</span>
          <Link href="/detections" />
        </Menu.Item>
        <Menu.Item key="/incidents">
            <img src={IncidentsIcon} />
          <span>Incidents</span>
          <Link href="/incidents" />
        </Menu.Item>
        <Menu.Item key="/escalation-policies">
            <img src={EscalationsIcon} />
          <span>Escalation Policies</span>
          <Link href="/escalation-policies" />
        </Menu.Item>
        <Menu.Item key="/groups">
          {groupsOnboardingActive ? (
            <Badge className="menu-badge" status={"processing"} color="red" />
          ) : null}
          <img src={GroupsIcon} />
          <span>Monitoring Scope</span>
          <Link href="/groups" />
        </Menu.Item>
        <Menu.Item key="/sources">
          {sourcesOnboardingActive ? (
            <Badge className="menu-badge" status={"processing"} color="red" />
          ) : null}
          <img src={SourcesIcon} />
          <span>Sources</span>
          <Link href="/sources" />
        </Menu.Item>
        <Menu.Item key="/destinations">
          <img src={DestinationsIcon} />
          <span>Destinations</span>
          <Link href="/destinations" />
        </Menu.Item>
        {session.workspace?.role !== Role.Guest ? (
          <Menu.Item key="/credentials">
            <Icon type="key" style={{ transform: "translateY(1px)" }} />
            <span>Credentials</span>
            <Link href="/credentials" />
          </Menu.Item>
        ) : (
          undefined
        )}
      </Menu>
    </Layout.Sider>
    </>
  )
}

export default Sidebar
