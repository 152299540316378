import React, { useState, useContext, useCallback, useEffect } from "react"
import { useNavigation, useCurrentRoute } from "react-navi"

import { Spin } from "antd"

import { ApolloError } from "apollo-client"
import { useApolloClient } from "@apollo/react-hooks"

import {
  User,
  Workspace,
  Onboarding,
  useLogoutMutation,
  useOnboardingGuideMutation,
  useAuthRouteQuery,
} from "@app/graphql"
import { Redirect, checkAuthRequired } from "./Router"

interface SessionContextNoLogout {
  user?: User | null
  workspace?: Workspace | null
  onboarding?: Onboarding | null
  loading?: boolean | null
  onboardingLoading?: boolean | null
  error?: ApolloError | null
}

interface SessionContext extends SessionContextNoLogout {
  handleLogout: () => void
  handleLogoutNoRedirect: () => void
  refetchOnboarding: () => void
  handleWorkspaceChange: (callback?: () => void) => void
}

const SessionStateContext = React.createContext<SessionContext | undefined>(
  undefined
)

interface SessionProviderProps {
  children: React.ReactNode
}

const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
}: SessionProviderProps) => {
  const [state, setState] = useState<SessionContextNoLogout>({})

  const client = useApolloClient()
  const navigation = useNavigation()
  const route = useCurrentRoute()

  const nextPath: string = route.url.href
  const { pathname } = route.url
  const authRequired = checkAuthRequired(pathname)

  const authQuery = useAuthRouteQuery()
  const [logout] = useLogoutMutation()
  const [onboardingMutation, onboardingStatus] = useOnboardingGuideMutation({})

  const noWorkspace = !authQuery.data?.currentWorkspace
  const authStatus = localStorage.getItem("client-authorized")

  useEffect(() => {
    if (
      authQuery.data?.currentUser &&
      !noWorkspace &&
      !onboardingStatus.called
    ) {
      onboardingMutation().catch(e => {})
    }

    const onboarding = onboardingStatus.data?.onboardingGuide?.onboarding
    setState({
      user: authQuery.data?.currentUser,
      workspace: authQuery.data?.currentWorkspace,
      onboarding: onboarding ? onboarding : state.onboarding,
      loading: authQuery.loading,
      onboardingLoading: onboardingStatus.loading,
      error: authQuery.error,
    })

    if (authRequired && authStatus === "false") {
      navigation._history.replace(
        nextPath === "/" || pathname === "/login"
          ? "/login"
          : `/login?next=${nextPath}`
      )
    }

    /* eslint-disable */
  }, [
    onboardingMutation,
    onboardingStatus,
    authQuery,
    authRequired,
    pathname,
    authStatus,
  ])
  /* eslint-enable */

  const handleLogoutNoRedirect = useCallback(async () => {
    try {
      localStorage.setItem("client-authorized", "false")
      await logout()
      await client.resetStore()
    } catch (e) {
      setState({})
    }
  }, [client, logout])

  const handleLogout = useCallback(async () => {
    try {
      localStorage.setItem("client-authorized", "false")
      await logout()
      await client.resetStore()
    } catch (e) {
      navigation._history.replace("/login")
      setState({})
    }
  }, [client, navigation, logout])

  const handleWorkspaceChange = useCallback(
    async (callback?: () => void) => {
      try {
        await client.clearStore()
        if (callback) callback()
        await authQuery.refetch()
        if (authQuery.data?.currentWorkspace) await onboardingMutation()
      } catch (e) {}
    },
    [client, authQuery, onboardingMutation]
  )

  if (
    authQuery.data?.currentUser &&
    noWorkspace &&
    route.url.pathname !== "/no-organization"
  ) {
    return (
      <div
        className="center-page"
        style={{ marginLeft: "230px", paddingTop: "15px" }}
      >
        <Spin spinning={true} />
        <Redirect href="/no-organization" noText timeout={100} />
      </div>
    )
  }

  return (
    <SessionStateContext.Provider
      value={{
        ...state,
        handleLogout,
        handleLogoutNoRedirect,
        refetchOnboarding: noWorkspace ? () => {} : onboardingMutation,
        handleWorkspaceChange,
      }}
    >
      {children}
    </SessionStateContext.Provider>
  )
}

const useSession = () => {
  const context = useContext(SessionStateContext)
  if (context === undefined)
    throw new Error("useSession must be used within a SessionProvider")

  return context
}

export { SessionProvider, useSession }
