import React, { Suspense, useRef } from "react"

import { Router, View, useCurrentRoute, NotFoundBoundary } from "react-navi"

import { HelmetProvider } from "react-navi-helmet-async"
import { ApolloProvider } from "@apollo/react-hooks"

import client from "./helpers/apolloClient"
import routes, { checkAuthRequired } from "./router/Router"

import Sidebar from "./components/sidebar/main"
import Page404 from "./pages/Page404"

import { Alert } from "antd"

import { SessionProvider, useSession } from "./router/SessionContext"
import { RefetchRef, RefetchProvider } from "./router/PageRefetch"

const App: React.FC = () => {
  return (
    <div className="App">
      <HelmetProvider>
        <ApolloProvider client={client}>
          <Router routes={routes}>
            <Suspense fallback={null}>
              <NotFoundBoundary render={() => <Page404 />}>
                <SessionProvider>
                  <Content />
                </SessionProvider>
              </NotFoundBoundary>
            </Suspense>
          </Router>
        </ApolloProvider>
      </HelmetProvider>
    </div>
  )
}

const Content: React.FC = () => {
  const session = useSession()

  const refetchRef = useRef<RefetchRef>(null)

  const route = useCurrentRoute()
  const unauthorized = !checkAuthRequired(route.url.pathname)

  if (!unauthorized && !session.user) return <></>
  if (
    unauthorized ||
    !session.user ||
    !session.workspace ||
    route.url.pathname === "/no-organization"
  ) {
    return <View />
  }

  return (
    <div>
      <Sidebar refetchPage={refetchRef?.current?.forceRefetch} />
      {session.workspace?.demo ? (
        <Alert
          type="warning"
          message="You are currently in demo organization!"
          className="alert-top-page alert-demo center-text alert-fixed"
        />
      ) : null}
      <div
        style={session.workspace?.demo ? { paddingTop: "40px" } : undefined}
        className="page-content"
      >
        <RefetchProvider ref={refetchRef}>
          <View />
        </RefetchProvider>
      </div>
    </div>
  )
}

export default App
